import React, {Fragment, useEffect, useState} from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import {
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormGroup, Input,
    Label,
    Popover,
    PopoverBody,
    PopoverHeader,
    Row
} from "reactstrap";

import {useDispatch, useSelector} from "react-redux";
import draftOrderReducer from "./../store";
import {getDraftOrders, selectDraftOrder, closeDialog, openDialog, updateImportOrders, setDraftOrderFilter} from "./../store/draftOrderSlice";
import {useForm} from "react-hook-form";
import {useHistory} from "react-router-dom";
import ModalFormSubmit from "../../../share-components/form/modalFormSubmit";
import { toast } from 'react-toastify';
import withReducer from "../../../store/withReducer";
import {mapSearchAutoSuggestion} from "../../../utils/mapper";
import PreLoader from "../../../share-components/ui/preLoader";
import ImportOreders from "./content/importOrders";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import DatePicker from "../../../share-components/ui/DateRangePicker";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import {DateRangePicker} from "rsuite";
import {sellerAutoSuggestionApi} from "../../../services/manage-product/productService";
import moment from "moment";
import {getBrands} from "../../manage-product/store/inventorySlice";
import {getCustomerList, setCustomers} from "../../backInStock/store/backStockSlice";
import BackInStockTable from "../../backInStock/content/backInStockTable";
import {draftOrderFilters} from "../../manage-product/product/edit/content/settings/map";
import {customerAutoSuggestionApi} from "../../../services/order";

const {afterToday} = DateRangePicker;


const schema = yup.object().shape({
    file: yup.mixed().test("required", "You need to provide a file", (file) => {
        if (file) return true;
        return false;
      }),
    partner: yup.object().required('partner is required field').typeError('Please select partner')
});

const PopoverBasicItem = (props) => {
    const {id, btntext, Popoverbody} = props;
    const [popover, setPopover] = useState(false)
    const Toggle = () => setPopover(!popover);
    return (
        <>
            <p className="example-popover" id={"Popover-" + id}>{btntext}</p>
            <Popover
                placement="right"
                isOpen={popover}
                target={"Popover-" + id}
                toggle={Toggle}
                trigger="hover"
            >
                <PopoverBody>
                    {Popoverbody}
                </PopoverBody>
            </Popover>
        </>
    );
};

const customStyles = {
    control: base => ({
        ...base,
        minHeight: 50,
        border: 'none',
    })
};

const DraftOrder = (props) => {
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const [brand, setBrand] = useState(null);
    const [search, setSearch] = useState(null);
    const [timer, setTimer] = useState(null);
    let history = useHistory();
    const sellerState = useSelector(({order}) => order.draftOrder);
    const TodayDate = new Date();
    const sevenDays = new Date().setDate(TodayDate.getDate() - 90);
    const [dateRange, setDateRange] = useState([sevenDays, TodayDate]);
    const userState =  useSelector(({authReducer}) => authReducer.data);
    const [seller, setSeller] = useState(null);
    const [brands, setBrands] = useState(null);
    const [brandsVal, setBrandsVal] = useState(null);
    const [customer, setCustomer] = useState(null);
    const orderState = useSelector(({order}) => order.order);
    const backInRequestState = useSelector(({backStore}) => backStore.backStoreRequest);
    const [startDate, endDate] = dateRange;
    const [searchSeller, setSearchSeller] = useState('');
    const authData =  useSelector(({authReducer}) => authReducer);
    const [orderCheckBoxData, setOrderCheckBoxData] = useState({idArray: []});
    const [removed, setRemoved] = useState(false);
    const [isChecked, setIsChecked] = useState(null);
    const [itemIdArray, setItemIdArray] = useState([]);
    const draftOrderData = useSelector(selectDraftOrder);
    const submitTypes = ["submit", "update", "save"];
    const [differentPage, setDifferentPage] = useState(!submitTypes.includes(history.location.state?.from));
    const [loading, isSetLoading] = useState(history.location.state?.from !== "update" || draftOrderData.length < 1);
    const [isFilterable, setIsFilterable] = useState((history.location.state?.from !== "update" || draftOrderData.length < 1));

    useEffect(() => {
        if (differentPage) {
            dispatch(setDraftOrderFilter(draftOrderFilters(authData.defaultSeller)));
        }
    }, []);

    useEffect(()=>{
        if (!differentPage && (isFilterable ||  ((isFilterable)&&authData.defaultSeller?.value != sellerState.filter.seller?.value))) {
            dispatch(setDraftOrderFilter({
                ...sellerState.filter,
                seller: authData.defaultSeller,
                currentPage: 1
            }));
        }
    },[authData.defaultSeller])

    const dataMenu = [
        {
            type: 0,
            text: 'Draft Order',
        }
    ];

    const defaultValues = {
        file: '',
        partner:''
    };

    function isJSON(data) {
        const csvRegex = /^(?:(?:"(?:[^"]|"")*"(?:,|$))|(?:[^",]*))(?:,(?:(?:"(?:[^"]|"")*"(?:,|$))|(?:[^",]*)))*$/;
        return csvRegex.test(data);
    }

    const onSubmitImportOrders = (dialog, data) => {
        if(data){
            setButtonDisable(true);
            data.partner=data.partner?.value;
           
            dispatch(updateImportOrders({...data})).then(res => {
                if (!res.error) {
                    setButtonDisable(false);
                    if(!isJSON(res.payload)) {
                        const outputFilename = `draft-orders_${(new Date().toJSON().slice(0, 10))}.csv`;
                        const url = window.URL.createObjectURL(new Blob([res.payload]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', outputFilename);
                        document.body.appendChild(link);
                        link.click();
                    }
                    dispatch(closeDialog());
                    getData();
                    dispatch(closeDialog());
                }else{
                    toast.error(res.error.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    setButtonDisable(false);
                }
            });
        }

    }

    const {
        control,
        handleSubmit: handleSubmitImportOeders,
        formState: formImportOrders,
        reset: resetImportOrders,
        register: register,
        setValue: setValue,
        getValues: getValues,
        trigger:trigger,
    } = useForm({
        mode: 'onChange',
        defaultValues: defaultValues,
        resolver: yupResolver(schema)
    });

    const closeImportOrders = () => {
        resetImportOrders(defaultValues);
        dispatch(closeDialog());
    }

    const [buttonDisable, setButtonDisable] = useState(false);
    const {errors: errors} = formImportOrders;

    const tableColumns = [
        {
            name: 'Draft Order No',
            selector: row => row.draft_order_no,
            sortable: true,
            center: false,
        }, {
            name: 'Order ID',
            selector: row => row.shopify_id,
            sortable: true,
            center: false,

        },
        {
            name: 'Customer',
            selector: row => row.customer,
            sortable: true,
            center: false,
        },
        {
            name: 'Seller',
            selector: row => row.seller,
            sortable: true,
            center: false,
        },
        {
            name: 'Amount',
            selector: row => row.amount,
            sortable: true,
            center: false,
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
            center: false,
        },
        {
            name: 'Created Date',
            selector: row => row.order_date,
            sortable: true,
            center: false,
        },
        {
            name: 'Action',
            selector: row => (row.is_action) ?
                <div><a onClick={() => {
                    onEdit(row);
                }}><i className="fa fa-pencil"
                      style={{width: 25, fontSize: 16, padding: 11, color: 'rgb(40, 167, 69)'}}></i></a>
                </div> : "",
            sortable: false,
            center: true,
        }
    ];

    const toggleRowSelected = (id) => {
        // console.log(id)
    }

    const getData=()=>{
        if (isFilterable) {
            const filter = sellerState.filter;
            if (differentPage && JSON.stringify(filter) != JSON.stringify(draftOrderFilters(authData.defaultSeller))) {
                setDifferentPage(false);
            } else {
                console.log('Filter', sellerState.filter);
                dispatch(getDraftOrders({
                    page:filter.currentPage,
                    pageSize: filter.pageSize,
                    start_date: moment(startDate).format('YYYY-MM-DD'),
                    end_date: moment(endDate).format('YYYY-MM-DD'),
                    seller: sellerState.filter.seller? sellerState.filter.seller.value:'',
                    brand:sellerState.filter.brand? sellerState.filter.brand.id:'',
                    customer:sellerState.filter.customer? sellerState.filter.customer.value:'',
                    name: sellerState.filter.name,
                    status: sellerState.filter.status?.value,
                })).then(res=> {
                    isSetLoading(false);
                })
                
                setDifferentPage(false);
            }
        }
        setIsFilterable(true);
    }
    
    useEffect(() => {
        getData();
    }, [sellerState.filter.currentPage, sellerState.filter.pageSize, startDate, endDate, sellerState.filter.seller, sellerState.filter.brand, sellerState.filter.customer, sellerState.filter.name, sellerState.filter.status]);

    useEffect(()=>{
        dispatch(getCustomerList()).then(res => {
            dispatch(setCustomers(res.payload))
        });
    },[dispatch])

    const onEdit = (row) => {
        history.push('/order/draft/' + row.id + '/edit');
    }
    const onView = (row) => {
        //history.push('seller/' + row.id + '/view');
    }
    const onAdd = () => {
        history.push('/order/draft/create');
    }
    const draftOrders = useSelector(selectDraftOrder);

    const sellerPromiseOption = (inputValue) =>
        new Promise((resolve) => {
            const data = {
                value: inputValue
            }
            setTimeout(() => {
                resolve(mapSearchAutoSuggestion(sellerAutoSuggestionApi(data)))
            });
        });

    const customerPromiseOption = (inputValue) =>
        new Promise((resolve) => {
            const data = {
                value: inputValue
            }
            setTimeout(() => {
                resolve(mapSearchAutoSuggestion(customerAutoSuggestionApi(data)))
            });
        });

    useEffect(() => {
        const data = {
            value: '',
            seller_id: sellerState.filter.seller?.value
        }
        dispatch(getBrands(data)).then(res=>{
            setBrands(res.payload);
        });
    },[dispatch, sellerState.filter.seller]);

    let idArray = [];
    const handleChange = (state) => {
        // idArr = [...itemIdArray]
        if (state.selectedCount>0) {
            setIsChecked(true);
            state.selectedRows.map((item, i) => {
                if (idArray.includes(item.id)!==-1) {
                    idArray.push(item.id);
                    // setItemIdArray(idArray);
                    setOrderCheckBoxData(prevState => ({
                        idArray:[...prevState.idArray, ...idArray],
                    }));
                }
            });
        }

        idArray = Array.from(new Set(idArray));
        setItemIdArray(idArray)
    }

    const pageChange = (e) => { 
        dispatch(setDraftOrderFilter({...sellerState.filter, currentPage:e}))
    }
    
    const onPageSizeChange = (e) => {
        dispatch(setDraftOrderFilter({...sellerState.filter, currentPage: 1, pageSize:e}))
    }

    const statusOptions = [
        { value: "completed", label: "Completed" },
        { value: "open", label: "Open" },
    ];
    
    return (
        <Fragment>
            <Breadcrumb title="Draft Order" data={dataMenu}/>
            <Container fluid={true} className="product-wrapper">
                {loading ? <>
                    <PreLoader></PreLoader>
                </> :
                <>
                    <div className="product-grid">
                        <div className="feature-products">
                            <Card>
                                <Row>
                                    <Col sm="12">
                                        <div className='float-sm-right p-3'>
                                            <a onClick={onAdd} className="btn btn-sm btn-primary"> 
                                                <i className="fa fa-plus"></i> Add Order
                                            </a>
                                        </div>
                                        <div className='float-sm-right p-3'>
                                            <a className="btn btn-sm btn-primary"
                                                onClick={() => {
                                                    dispatch(openDialog())
                                                }}
                                                > 
                                                <i className="fa fa-download"></i> Import Orders
                                            </a>
                                        </div>
                                    </Col>
                                </Row>
                            </Card>
                     

                            <Row>
                                <Col md={userState.user.role.name!=="Seller" ? 3 :4}>
                                    <div className="dashboard-datepicker">
                                        <DatePicker
                                            dateRange={dateRange}
                                            dateRangeChange={(date) => {
                                                setDateRange(date)
                                            }}
                                            defaultValue={[TodayDate, sevenDays]}
                                            disabledDate={afterToday()}
                                        />
                                    </div>
                                </Col>
                                {userState.user.role.name!=="Seller" && <Col md={userState.user.role.name!=="Seller" ? 3 :4}>
                                    <AsyncSelect
                                        className="select2-filter"
                                        isClearable={true}
                                        menuPortalTarget={document.body}
                                        defaultOptions
                                        placeholder="Seller"
                                        loadOptions={sellerPromiseOption}
                                        value={sellerState.filter.seller}
                                        styles={{...customStyles, menuPortal: base => ({ ...base, zIndex: 100 }) }}
                                        components={{
                                            IndicatorSeparator: () => null
                                        }}
                                        onClear={(e) => {

                                        }}
                                        onChange={(e) => {
                                            dispatch(setDraftOrderFilter({
                                                ...sellerState.filter,
                                                seller:e ? e : null,
                                                currentPage: 1,
                                            }));
                                        }}
                                    />
                                </Col>}
                                <Col  md={userState.user.role.name!=="Seller" ? 3 :4}>
                                    <Select
                                        placeholder={<div>Seller Brand</div>}
                                        className="select2-filter"
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) => option.id}
                                        isClearable
                                        cacheOptions
                                        styles={customStyles}
                                        options={brands}
                                        defaultValue={sellerState.filter.brand}
                                        onChange={(e) => {
                                            dispatch(setDraftOrderFilter({
                                                ...sellerState.filter,
                                                brand:e ? e : null,
                                                currentPage: 1,
                                            }));
                                        }}
                                        components={{
                                            IndicatorSeparator: () => null
                                        }}
                                    />
                                </Col>
                                <Col md={userState.user.role.name!=="Seller" ? 3 :4}>
                                    <AsyncSelect
                                        className="select2-filter"
                                        isClearable={true}
                                        cacheOptions
                                        defaultOptions
                                        placeholder="Customer"
                                        loadOptions={customerPromiseOption}
                                        value={sellerState.filter.customer}
                                        styles={{...customStyles, menuPortal: base => ({ ...base, zIndex: 100 }) }}
                                        components={{
                                            IndicatorSeparator: () => null
                                        }}
                                        onClear={(e) => {

                                        }}
                                        onChange={(e) => {
                                            dispatch(setDraftOrderFilter({
                                                ...sellerState.filter,
                                                customer:e ? e : null,
                                                currentPage: 1,
                                            }));
                                        }}
                                    />
                                </Col>
                                <Col md={userState.user.role.name !== "Seller" ? 3 : 4} className="mt-2">
                                    <Select
                                    placeholder={<div>Status</div>}
                                    className="select2-filter"
                                    isClearable
                                    cacheOptions
                                    styles={customStyles}
                                    options={statusOptions}
                                    defaultValue={sellerState.filter.status}
                                    onChange={(e) => {
                                        dispatch(
                                        setDraftOrderFilter({
                                            ...sellerState.filter,
                                            status: e ? e : null,
                                            currentPage: 1,
                                        })
                                        );
                                    }}
                                    components={{
                                        IndicatorSeparator: () => null,
                                    }}
                                    />
                                </Col>
                                <Col
                                    md={userState.user.role.name !== "Seller" ? 3 : 4}
                                    className="mt-2"
                                >
                                    <Form
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                    }}
                                    >
                                    <FormGroup className="m-0">
                                        <Input
                                        type="text"
                                        className="form-control"
                                        defaultValue={orderState.filter.name}
                                        onChange={(e) => {
                                            clearTimeout(timer);

                                            const newTimer = setTimeout(() => {
                                            // setName(e.target.value);
                                            dispatch(
                                                setDraftOrderFilter({
                                                ...sellerState.filter,
                                                name: e.target.value,
                                                currentPage: 1,
                                                })
                                            );
                                            }, 500);
                                            setTimer(newTimer);
                                        }}
                                        placeholder="Search by Draft Order No/Order Id"
                                        />
                                        <i className="fa fa-search"></i>
                                    </FormGroup>
                                    </Form>
                                </Col>
                            </Row>
                        </div>
                        <Row>
                            <Col className="mt-3" sm="12">
                                <Card>
                                    <CardBody>
                                        <div className="table-responsive product-table">
                                            <BackInStockTable
                                                paginationTotalRows={sellerState.sellerTableRow}
                                                idArray={orderCheckBoxData}
                                                remove={removed}
                                                tableColumns={tableColumns}
                                                backStockData={draftOrders}
                                                backInRequestState={sellerState}
                                                pageChange={pageChange}
                                                toggleRowSelected={toggleRowSelected}
                                                handleChange={handleChange}
                                                paginationRowsPerPageOptions={[10,25,50,100]}
                                                onPageSizeChange={onPageSizeChange}
                                                paginationDefaultPage={sellerState.filter.currentPage}
                                                paginationPerPage={sellerState.filter.pageSize}
                                            />
                                            {/*<CommonDataTable*/}
                                            {/*    headerColumns={tableColumns}*/}
                                            {/*    gridData={draftOrders}*/}
                                            {/*    noAction*/}
                                            {/*    paginationServer*/}
                                            {/*    paginationTotalRows={sellerState.sellerTableRow}*/}
                                            {/*    paginationPerPage={10}*/}
                                            {/*    paginationComponentOptions={{*/}
                                            {/*        noRowsPerPage: true*/}
                                            {/*    }}*/}
                                            {/*    onChangePage={page => setPage(page)}*/}
                                            {/*/>*/}

                                        </div>

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </>}
            </Container>

            <ModalFormSubmit
                defaultValues={defaultValues}
                onSubmitForm={onSubmitImportOrders}
                reset={resetImportOrders}
                formState={formImportOrders}
                handleSubmit={handleSubmitImportOeders}
                title='Import Orders'
                selector='order'
                dialog={({order}) => order.draftOrder.addDialog}
                closeDialog={closeImportOrders}
                buttonDisable={buttonDisable}
            >
                <ImportOreders control={control} errors={errors} trigger={trigger} register={register}
                                      setValue={setValue}
                                      getValues={getValues}></ImportOreders>
            </ModalFormSubmit>

        </Fragment>
    );
}

export default withReducer([{order: draftOrderReducer}])(DraftOrder);