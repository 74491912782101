import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import Select from 'react-select';
import { Label, Input } from 'reactstrap'

import { formatterDecimal } from "../../../../utils/common";


const ItemsTable = (props) => {
    const { orderItems, orderState, isDirectRefund, setOrderItems, isItemEditable } = props;

    const [isSelectedAllRows, setIsSelectedAllRows] = useState(false);

    const paymentStatusOptions = orderState.settings.returnReasons.map(reason => ({
        value: reason.id,
        label: reason.name
    }));


    useEffect(() => {
        if (!isDirectRefund) {
            setIsSelectedAllRows(false);
        }
    }, [isDirectRefund]);

    const handleEdit = (rowId, field, value) => {
        const updatedData = orderItems.map(row =>
            row.id === rowId ? { ...row, [field]: value } : row
        );
        setOrderItems(updatedData);
    };

    const handleReturnQty = (row, value) => {
        const _value = value ? parseFloat(value) : 0;
        const totalReturnAmount = row.price * _value;
        let itemErrors = row.itemErrors != undefined && row.itemErrors ? row.itemErrors : {};
        itemErrors.isErrorQty = false;
        if (_value <= 0 || _value > row.availableQuantity || (parseFloat(_value) - parseInt(_value)) != 0) {
            itemErrors.isErrorQty = true;
        }
        itemErrors.isError = itemErrors.isErrorQty || (itemErrors.isErrorReason != undefined && itemErrors.isErrorReason)
            || (itemErrors.isErrorReturnTotal != undefined && itemErrors.isErrorReturnTotal);

        const updatedData = orderItems.map(_row =>
            _row.id === row.id ? {
                ..._row,
                quantity: _value,
                returnTotal: totalReturnAmount.toFixed(2),
                returnTotalFormat: formatterDecimal.format(totalReturnAmount),
                itemErrors: itemErrors
            } : _row
        );
        setOrderItems(updatedData);
    };

    const handleReason = (row, value) => {
        let itemErrors = row.itemErrors != undefined && row.itemErrors ? row.itemErrors : {};
        itemErrors.isErrorReason = false;
        if (value == '' || value == null) {
            itemErrors.isErrorReason = true;
        }
        itemErrors.isError = itemErrors.isErrorReason || (itemErrors.isErrorQty != undefined && itemErrors.isErrorQty)
            || (itemErrors.isErrorReturnTotal != undefined && itemErrors.isErrorReturnTotal);

        const updatedData = orderItems.map(_row =>
            _row.id === row.id ? {
                ..._row,
                reason_id: value,
                itemErrors: itemErrors
            } : _row
        );
        setOrderItems(updatedData);
    };

    const handleReturnTotal = (row, value) => {
        const _value = parseFloat(parseFloat(value).toFixed(2)) || 0;
        const totalReturnAmount = parseFloat((row.price * row.quantity).toFixed(2));
        let itemErrors = row.itemErrors || {};

        itemErrors.isErrorReturnTotal = _value <= 0 || _value > totalReturnAmount;

        itemErrors.isError = itemErrors.isErrorReturnTotal || (itemErrors.isErrorQty != undefined && itemErrors.isErrorQty)
            || (itemErrors.isErrorReason != undefined && itemErrors.isErrorReason);

        const updatedData = orderItems.map(_row =>
            _row.id === row.id ? {
                ..._row,
                returnTotal: _value.toFixed(2),
                returnTotalFormat: formatterDecimal.format(_value),
                itemErrors: itemErrors
            } : _row
        );
        setOrderItems(updatedData);
    };

    const handleSelectRow = (row, value) => {
        let itemErrors = {
            isError: false,
            isErrorReason: false,
            isErrorQty: false,
            isErrorReturnTotal: false
        };

        const updatedData = orderItems.map(_row =>
            _row.id === row.id ? {
                ..._row,
                selected: value,
                quantity: 0,
                returnTotal: 0.00,
                returnTotalFormat: formatterDecimal.format(0),
                reason_id: null,
                isRestock: false,
                itemErrors: { ...itemErrors }
            } : _row
        );
        setOrderItems(updatedData);
    };

    const handleSelectAllRows = (isChecked) => {
        let itemErrors = {
            isError: false,
            isErrorReason: false,
            isErrorQty: false,
            isErrorReturnTotal: false
        };

        const updatedData = orderItems.map(_row =>
            !(_row.return_request_id || _row.made_to_measure_request_id || _row.cancel_request_id || _row.refund_request_id) && ((isChecked === true && _row.selected === false) || isChecked === false) ? {
                ..._row,
                selected: isChecked,
                quantity: 0,
                returnTotal: 0.00,
                returnTotalFormat: formatterDecimal.format(0),
                reason_id: null,
                isRestock: false,
                itemErrors: { ...itemErrors }
            } : _row
        );
        setOrderItems(updatedData);
    }

    const displayProductDetailCol = (row) => {
        return (
            <div className='d-flex align-items-center py-2'>
                <img src={row.image} alt="Image" className='mr-2' style={{ width: '50px' }} />
                <div>
                    <p className='m-0 text-dark font-weight-700'>{row.name}</p>
                    <p className='m-0 text-muted'>{row.sku}</p>
                    <p className='m-0 text-muted'>{row.colour}/{row.size}</p>

                    {row.return_request_id && (
                        <p className="text-danger">
                            Customer has already raised return request for this item. <br />
                            <a target="_blank" style={{ fontWeight: 'bold' }} href={`${process.env.PUBLIC_URL}/order/pending-return-requests/${row.return_request_id}/edit`}>Click here</a> to check that request.
                        </p>
                    )}

                    {row.made_to_measure_request_id && (
                        <p className="text-danger">
                            Customer has already raised Alteration request for this item. <br />
                            <a target="_blank" style={{ fontWeight: 'bold' }} href={`${process.env.PUBLIC_URL}/order/alteration-requests/${row.made_to_measure_request_id}/view`}>Click here</a> to check that request.
                        </p>
                    )}

                    {row.cancel_request_id && (
                        <p className="text-danger">
                            Customer has already raised cancel request for this item. <br />
                            <a target="_blank" style={{ fontWeight: 'bold' }} href={`${process.env.PUBLIC_URL}/order/pending-cancel?cancel_request_id=${row.cancel_request_id}`}>Click here</a> to check that request.
                        </p>
                    )}

                    {row.refund_request_id && (
                        <p className="text-danger">
                            This item refund request is in the pending section. <br />
                            <a target="_blank" style={{ fontWeight: 'bold' }} href={`${process.env.PUBLIC_URL}/order/return?refund_request_id=${row.refund_request_id}`}>Click here</a> to check that request.
                        </p>
                    )}
                </div>
            </div>
        );
    };

    const columns = [
        {
            name: <div className={'only-checkbox checkbox' + (isItemEditable ? ' checkbox-primary' : ' checkbox-light')}>
                <input
                    onChange={(e) => {
                        setIsSelectedAllRows(e.target.checked);
                        handleSelectAllRows(e.target.checked);
                    }}
                    type={"checkbox"}
                    checked={isSelectedAllRows}
                    disabled={!isItemEditable}
                    id='selectAllRowsCheckbox' />
                <label htmlFor='selectAllRowsCheckbox'></label>
            </div>,
            selector: row => row.selected,
            cell: (row) => (
                <div className={'only-checkbox checkbox' + (row.return_request_id || row.made_to_measure_request_id || row.cancel_request_id || row.refund_request_id ? ' checkbox-light' : ' checkbox-primary')}>
                    <input
                        type="checkbox"
                        checked={row.selected}
                        onChange={e => handleSelectRow(row, e.target.checked)}
                        disabled={row.return_request_id || row.made_to_measure_request_id || row.cancel_request_id || row.refund_request_id}
                        id={'selectRowCheckbox' + row.id}
                    />
                    <label htmlFor={'selectRowCheckbox' + row.id}></label>
                </div>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '50px',
            // grow: 0.2, // column size
        },
        {
            name: 'Product Name',
            selector: row => row.name,
            cell: row => displayProductDetailCol(row),
            grow: 2, // column size
        },
        {
            name: 'Price (£)',
            selector: row => row.priceFormat,
            right: true,
            grow: 0.5, // column size
        },
        {
            name: 'Order Qty',
            selector: row => row.availableQuantity,
            center: true,
            grow: 0.5, // column size
        },
        {
            name: <><strong>Return Qty </strong> <span className='text-danger ml-1'>*</span></>,
            selector: row => row.quantity,
            cell: row => {
                let isError = row.itemErrors != undefined && row.itemErrors.isErrorQty != undefined && row.itemErrors.isErrorQty;
                return (
                    row.selected ? (
                        <input
                            type="number"
                            value={row.quantity}
                            onChange={e => handleEdit(row.id, 'quantity', e.target.value)}
                            onBlur={e => handleReturnQty(row, e.target.value)}
                            className={'form-control form-control-sm text-center' + (isError ? ' input-error' : '')}
                            style={{ width: '75px' }}
                        />
                    ) : (
                        row.quantity
                    )
                );
            },
            center: true,
            grow: 0.5, // column size
        },
        {
            name: <>
                <span>Return Total (£)</span>
                {isDirectRefund && <span className='text-danger ml-1'>*</span>}
            </>,
            selector: row => row.returnTotal,
            cell: row => {
                let isError = row.itemErrors != undefined && row.itemErrors.isErrorQty != undefined && row.itemErrors.isErrorQty;
                return (
                    row.selected && isDirectRefund ? (
                        <input
                            type="number"
                            value={row.returnTotal}
                            onChange={e => handleEdit(row.id, 'returnTotal', e.target.value)}
                            onBlur={e => handleReturnTotal(row, e.target.value)}
                            className={'form-control form-control-sm text-center' + (isError ? ' input-error' : '')}
                            style={{ width: '75px' }}
                        />
                    ) : (
                        row.returnTotalFormat
                    )
                )
            },
            grow: 0.5, // column size
        },
        {
            name: <><strong>Reason</strong> <span className='text-danger ml-1'>*</span></>,
            selector: row => row.reason_id,
            cell: row => {
                const opt = paymentStatusOptions.find(option => option.value === row.reason_id);
                const customStyles = {
                    menuPortal: base => ({ ...base, zIndex: 9999 }), // Ensure the dropdown is above other elements
                    control: base => {
                        var styles = {
                            ...base,
                            minHeight: '30px', // Adjust the height of the input
                            fontSize: '12px',   // Adjust the font size
                            boxShadow: 'none',
                        };
                        if (row.itemErrors != undefined && row.itemErrors.isErrorReason != undefined && row.itemErrors.isErrorReason == true) {
                            styles.borderColor = "var(--danger)";
                        }
                        return styles;
                    },
                    dropdownIndicator: base => ({
                        ...base,
                        padding: '4px' // Adjust the padding of the dropdown indicator
                    }),
                    clearIndicator: base => ({
                        ...base,
                        padding: '4px' // Adjust the padding of the clear indicator
                    }),
                    valueContainer: base => ({
                        ...base,
                        padding: '0px 6px' // Adjust the padding of the value container
                    }),
                    input: base => ({
                        ...base,
                        margin: '0px' // Adjust the margin of the input
                    })
                };

                return (
                    row.selected ? (
                        <Select
                            placeholder={<div>Reason</div>}
                            className="select2-filter w-100"
                            isClearable
                            cacheOptions
                            styles={customStyles}
                            options={paymentStatusOptions}
                            defaultValue={opt}
                            onChange={(e) => handleReason(row, e ? e.value : null)}
                            components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator: () => null
                            }}
                            menuPortalTarget={document.body} // Render the dropdown menu in the body
                        />
                    ) : (
                        opt ? opt.label : '-'
                    )
                );
            },
            grow: 1, // column size
        },
        {
            name: 'Is Restock',
            selector: row => row.isRestock,
            cell: row => (
                row.selected ? (
                    <div className='only-checkbox checkbox checkbox-success'>
                        <input
                            type="checkbox"
                            checked={row.isRestock}
                            onChange={e => handleEdit(row.id, 'isRestock', e.target.checked)}
                            id={'restockCheckbox' + row.id}
                        />
                        <label htmlFor={'restockCheckbox' + row.id}></label>
                    </div>
                ) : (
                    row.isRestock ? 'Yes' : 'No'
                )
            ),
            center: true,
            grow: 0.5, // column size
        },
    ];

    const conditionalRowStyles = [
        {
            when: row => row.selected == true && !(row.itemErrors != undefined && row.itemErrors.isError != undefined && row.itemErrors.isError == true),
            style: {
                backgroundColor: 'rgba(206, 240, 253, 0.9)',
            },
        },
        {
            when: row => row.selected == true && (row.itemErrors != undefined && row.itemErrors.isError != undefined && row.itemErrors.isError == true),
            style: {
                backgroundColor: 'rgba(253, 206, 206, 0.9)',
            },
        },
    ];

    return (
        <DataTable
            columns={columns}
            data={orderItems}
            selectableRows={false}
            highlightOnHover
            //pagination
            conditionalRowStyles={conditionalRowStyles}
        />
    );
};

export default ItemsTable;
